import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { css, styled } from "goober";
import { t as translate } from "i18next";

import { readState } from "@/__main__/app-state.mjs";
import type { Meta } from "@/__main__/router.mjs";
import CategoryHeader from "@/game-eft/components/flea-market/FleaCategoryHeader.jsx";
import FleaItemTile from "@/game-eft/components/flea-market/FleaItemTile.jsx";
import {
  itemDetailsCardCss,
  itemDetailsTable,
  itemDetailsWrapperCss,
  itemWrapperCss,
  priceDetailsGridCss,
} from "@/game-eft/components/flea-market/FleaMarket.styled.jsx";
import ItemGrid from "@/game-eft/components/flea-market/ItemGrid.jsx";
import {
  TarkovCurrency,
  tradeValueColor,
} from "@/game-eft/constants/constants.mjs";
import {
  DEFAULT_CURRENCY_FORMAT_OPTIONS,
  INVALID_FLEA_ITEMS,
} from "@/game-eft/constants/flea-market.mjs";
import type { WikiItem, WikiTrader } from "@/game-eft/models/model-wiki.mjs";
import {
  calculateFleaTax,
  findAllTradersForItem,
  findBestTraderForItem,
  getTraderNativePrice,
  getTraderPriceForItem,
  getTraderPriceInRoubles,
} from "@/game-eft/utils/flea-market.mjs";
import {
  getItemImageURL,
  getTraderImageURL,
} from "@/game-eft/utils/images.mjs";
import useWeaponImages from "@/game-eft/utils/use-weapon-images.mjs";
import {
  getWikiData,
  useWikiData,
  WikiDataContextProvider,
} from "@/game-eft/utils/use-wiki-data.jsx";
import type { Cell } from "@/shared/DataTable.jsx";
import DataTable from "@/shared/DataTable.jsx";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import { TimeAgo } from "@/shared/Time.jsx";
import { classNames } from "@/util/class-names.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const RECENT_AVG_TRANSLATION = [
  "eft:fleaMarket.recentAvgPrice",
  "Avg. Flea Price",
] as const;
const LAST_DAY_AVG_TRANSLATION = [
  "eft:fleaMarket.lastDayAvgPrice",
  "24h Avg. Price",
] as const;
const LAST_WEEK_AVG_TRANSLATION = [
  "eft:fleaMarket.lastWeekAvgPrice",
  "7d Avg. Price",
] as const;

const RECENT_LOWEST_TRANSLATION = [
  "eft:fleaMarket.lowestRecentPrice",
  "Lowest Price",
] as const;
const LAST_DAY_LOWEST_TRANSLATION = [
  "eft:fleaMarket.lastDayLowestPrice",
  "24h Lowest Avg. Price",
] as const;
const LAST_WEEK_LOWEST_TRANSLATION = [
  "eft:fleaMarket.lastWeekLowestPrice",
  "7d Lowest Avg. Price",
] as const;

const TRADER_SOURCE_TRANSLATION = (traderName: string) =>
  [
    "eft:fleaMarket.traderPrice",
    "{{traderName}} Price",
    { traderName },
  ] as const;

const TRADER_PROFIT_TRANSLATION = [
  "eft:fleaMarket.traderProfit",
  "Trader Profit",
] as const;
const LAST_DAY_CHANGE_TRANSLATION = [
  "eft:fleaMarket.lastDayChange",
  "24h Price change",
] as const;
const LAST_WEEK_CHANGE_TRANSLATION = [
  "eft:fleaMarket.lastWeekChange",
  "7d Price change",
] as const;

const FEE_TRANSLATION = ["eft:fleaMarket.fee", "Fee"] as const;
const LAST_UPDATED_TRANSLATION = [
  "common:lastUpdated",
  "Last Updated",
] as const;

interface ItemProps {
  item?: WikiItem;
}

function Title({ item }: ItemProps) {
  const { t } = useTranslation();

  const itemName = item ? item.name : (["common:item", "Item"] as const);

  return <span>{t(...itemName)}</span>;
}

const PriceDetailsContainer = styled("div")`
  grid-column: span 1;
  display: flex;
  flex-direction: column;
  gap: var(--sp-1);
  padding: var(--sp-5) var(--sp-6);

  .price-source {
    color: var(--shade2);
  }

  .price-value {
    color: var(--price-color, var(--shade0));
  }
`;

const ItemInfoTableHeader = styled("div")`
  background-color: var(--shade7);
  border-radius: var(--br-lg) var(--br-lg) 0 0;
  padding: var(--sp-5) var(--sp-6) var(--sp-2);
  width: 100%;

  .item-display {
    margin: 0 auto;
  }
`;

const tableStyles = () => css`
  & .table-container th td:first-child .cell,
  & .table-container tr td:first-child .cell {
    padding-inline-start: var(--sp-6);
  }

  & .table-container th td:last-child .cell,
  & .table-container tr td:last-child .cell {
    padding-inline-end: var(--sp-6);
  }

  & tbody tr .cell {
    font-size: 0.8125rem;
  }

  & tbody tr td:first-child {
    color: var(--shade0);
  }

  & table thead {
    background-color: var(--shade7);
  }

  & .inline-icon {
    width: var(--sp-5);
    height: var(--sp-5);
    display: inline-block;
    margin: 0 var(--sp-1_5);
  }

  & .dim-text {
    color: var(--shade0-50);
  }

  & .trader-name {
    color: #b59c6d;
  }
`;

interface DataChangeDetailProps {
  source: Translation | string;
  value: number;
  showDelta?: boolean;
}

interface ItemPriceDetailsProps extends DataChangeDetailProps {
  currency: TarkovCurrency;
  color?: string;
}

function ItemPriceDetails({
  value = 0,
  currency = TarkovCurrency.RUB,
  source,
  color,
  showDelta,
}: ItemPriceDetailsProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const sourceName = Array.isArray(source) ? t(...source) : source;
  const valueStr = value
    ? value.toLocaleString(language, {
        ...DEFAULT_CURRENCY_FORMAT_OPTIONS,
        currency,
        signDisplay: showDelta ? "exceptZero" : "auto",
      })
    : "-";

  return (
    <PriceDetailsContainer className="flex column gap-1">
      <p className="type-callout price-source">{sourceName}</p>
      <p
        className="type-page-header price-value"
        style={{ "--price-color": color ?? "var(--shade0)" }}
      >
        {valueStr}
      </p>
    </PriceDetailsContainer>
  );
}

function PercentageChangeDetails({
  value = 0,
  source,
  showDelta,
}: DataChangeDetailProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const sourceName = Array.isArray(source) ? t(...source) : source;
  const color =
    value > 0 ? "var(--turq)" : value < 0 ? "var(--red)" : "var(--shade0)";
  const valueStr = value
    ? value.toLocaleString(language, {
        style: "percent",
        maximumFractionDigits: 1,
        signDisplay: showDelta ? "exceptZero" : "auto",
      })
    : "-";

  return (
    <PriceDetailsContainer className="flex column gap-1">
      <p className="type-callout price-source">{sourceName}</p>
      <h3
        className="type-page-header price-value"
        style={{ "--price-color": color ?? "var(--shade0)" }}
      >
        {valueStr}
      </h3>
    </PriceDetailsContainer>
  );
}

interface PricePerSlotProps {
  width: number;
  height: number;
}

function PricePerSlotLabel({ width, height }: PricePerSlotProps) {
  const {
    i18n: { language },
  } = useTranslation();
  const count = (width * height).toLocaleString(language);

  if (width * height === 1) {
    return (
      <Trans
        i18nKey="eft:fleaMarket.pricePerSlot_one"
        className="type-callout--bold"
        values={{ count }}
      >
        <span className="type-callout--bold">
          Price per slot:{" "}
          <span className="type-callout--semi dim-text">
            {"({{ count }} Slot)"}
          </span>
        </span>
      </Trans>
    );
  }

  return (
    <Trans
      i18nKey="eft:fleaMarket.pricePerSlot_other"
      className="type-callout--bold"
      values={{ count }}
    >
      <span className="type-callout--bold">
        Price per slot:{" "}
        <span className="type-callout--semi dim-text">
          {"({{ count }} Slots)"}
        </span>
      </span>
    </Trans>
  );
}

interface TraderLabelProps {
  trader: WikiTrader;
}

function SellToTraderLabel({ trader }: TraderLabelProps) {
  const { t } = useTranslation();

  const traderName = t(...trader.nickname);

  return (
    <Trans i18nKey="eft:fleaMarket.sellToTrader" values={{ traderName }}>
      <p className="type-callout--bold flex row align-center">
        Sell to{" "}
        <img className="inline-icon" src={getTraderImageURL(trader.avatarId)} />{" "}
        <span className="trader-name">{"{{ traderName }}"}</span>
      </p>
    </Trans>
  );
}

export default function FleaItemDetails() {
  const {
    parameters: [itemSlug],
  } = useRoute();

  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { categories, items } = useWikiData();
  const { eft } = useSnapshot(readState);
  const { getWeaponImageById } = useWeaponImages();

  const [itemId, item] = useMemo(() => {
    if (!items) return [];
    return Object.entries<WikiItem>(items).find(
      ([_id, item]) => item.slug === itemSlug,
    );
  }, [items, itemSlug]);

  const categoryInfo = categories?.[item?.category];
  const sameCategoryItems = useMemo(() => {
    if (!items) return null;
    return Object.entries<WikiItem>(items).filter(
      ([otherItemId, otherItem]) => {
        return (
          otherItem.category === item.category &&
          otherItemId !== itemId &&
          !INVALID_FLEA_ITEMS.includes(otherItemId) &&
          !otherItem.questItem &&
          otherItem.canSellOnFlea
        );
      },
    );
  }, [item, items, itemId]);
  const otherItems = useMemo(() => {
    if (!Array.isArray(sameCategoryItems)) return null;
    return sameCategoryItems.slice(
      Math.floor(Math.random() * Math.min(0, sameCategoryItems.length - 4)),
      4,
    );
  }, [sameCategoryItems]);

  if (!item) return null; // TODO: Loading state

  const fleaData = eft.fleaMarket?.[itemId];
  const recentFleaPrice = fleaData?.last_3_hour_avg ?? 0;
  const lowestRecentPrice = fleaData?.last_3_hour_avg_lowest_price ?? 0;
  const dayLowestAvg = fleaData?.last_1_day_avg_lowest_price ?? 0;
  const weekLowestAvg = fleaData?.last_7_day_avg_lowest_price ?? 0;
  const lastDayPrice = fleaData?.last_1_day_avg ?? 0;
  const lastWeekPrice = fleaData?.last_7_day_avg ?? 0;
  const lastUpdatedAt =
    fleaData?.last_3_hour_avg_last_offer_at ??
    fleaData?.last_1_day_avg_last_offer_at ??
    fleaData?.last_7_day_avg_last_offer_at;
  const itemImage =
    categoryInfo?.parent === "Weapons"
      ? (getWeaponImageById(itemId) ?? getItemImageURL(itemId))
      : getItemImageURL(itemId);
  const fleaTax = calculateFleaTax(item.basePrice, recentFleaPrice);
  const dayChange = recentFleaPrice / lastDayPrice - 1;
  const weekChange = recentFleaPrice / lastWeekPrice - 1;

  const { trader: bestTrader, bestPrice: bestTraderPrice } =
    findBestTraderForItem(item, itemId);
  const bestTraderName = bestTrader
    ? bestTrader.nickname
    : (["eft:common.trader", "Trader"] as const);
  const traderProfit = bestTrader
    ? getTraderPriceInRoubles(bestTrader, bestTraderPrice) - recentFleaPrice
    : 0;

  const validTraders = findAllTradersForItem(item, itemId);
  const traderRows = validTraders
    .reduce<Cell[][]>((acc, trader) => {
      const sellPrice = getTraderPriceForItem(trader, item);
      if (!sellPrice) return acc;
      acc.push([
        {
          display: <SellToTraderLabel trader={trader} />,
          value: 1,
        },
        {
          display: getTraderNativePrice(trader, sellPrice).toLocaleString(
            language,
            {
              ...DEFAULT_CURRENCY_FORMAT_OPTIONS,
              currency:
                trader.currency ?? DEFAULT_CURRENCY_FORMAT_OPTIONS.currency,
            },
          ),
          value: sellPrice,
        },
      ]);

      return acc;
    }, [])
    .sort((a, b) => (b[1].value as number) - (a[1].value as number));

  const itemTableRows: Cell[][] = [
    [
      {
        display: <PricePerSlotLabel width={item.width} height={item.height} />,
        value: 9,
      },
      {
        display: Math.round(
          lowestRecentPrice / (item.width * item.height),
        ).toLocaleString(language, DEFAULT_CURRENCY_FORMAT_OPTIONS),
        value: Math.round(lowestRecentPrice / (item.width * item.height)),
      },
    ],
    [
      {
        display: t(...FEE_TRANSLATION),
        value: 8,
      },
      {
        display: fleaTax.toLocaleString(
          language,
          DEFAULT_CURRENCY_FORMAT_OPTIONS,
        ),
        value: fleaTax,
      },
    ],
    [
      {
        display: t(...RECENT_AVG_TRANSLATION),
        value: 7,
      },
      {
        display: recentFleaPrice.toLocaleString(
          language,
          DEFAULT_CURRENCY_FORMAT_OPTIONS,
        ),
        value: recentFleaPrice,
      },
    ],
    [
      {
        display: t(...LAST_DAY_AVG_TRANSLATION),
        value: 6,
      },
      {
        display: lastDayPrice.toLocaleString(
          language,
          DEFAULT_CURRENCY_FORMAT_OPTIONS,
        ),
        value: lastDayPrice,
      },
    ],
    [
      {
        display: t(...LAST_WEEK_AVG_TRANSLATION),
        value: 5,
      },
      {
        display: lastWeekPrice.toLocaleString(
          language,
          DEFAULT_CURRENCY_FORMAT_OPTIONS,
        ),
        value: lastWeekPrice,
      },
    ],
    [
      {
        display: t(...LAST_DAY_LOWEST_TRANSLATION),
        value: 4,
      },
      {
        display: dayLowestAvg.toLocaleString(
          language,
          DEFAULT_CURRENCY_FORMAT_OPTIONS,
        ),
        value: dayLowestAvg,
      },
    ],
    [
      {
        display: t(...LAST_WEEK_LOWEST_TRANSLATION),
        value: 3,
      },
      {
        display: weekLowestAvg.toLocaleString(
          language,
          DEFAULT_CURRENCY_FORMAT_OPTIONS,
        ),
        value: weekLowestAvg,
      },
    ],
    ...traderRows,
    [
      {
        display: t(...LAST_UPDATED_TRANSLATION),
        value: 0,
      },
      {
        display: <TimeAgo date={lastUpdatedAt} formatStyle="narrow" />,
        value: 0,
      },
    ],
  ];

  return (
    <WikiDataContextProvider>
      <PageContainer>
        <PageHeader title={() => <Title item={item} />} image={itemImage} />
        <div className={itemDetailsWrapperCss()}>
          <div className="span-2 flex column gap-4">
            <div className={itemDetailsCardCss()}>
              <div className={priceDetailsGridCss()}>
                <ItemPriceDetails
                  source={RECENT_LOWEST_TRANSLATION}
                  value={lowestRecentPrice}
                  currency={TarkovCurrency.RUB}
                  color="var(--shade0)"
                />
                <ItemPriceDetails
                  source={TRADER_SOURCE_TRANSLATION(t(...bestTraderName))}
                  value={bestTraderPrice ?? 0}
                  currency={bestTrader?.currency}
                  color="var(--orange)"
                />
                <ItemPriceDetails
                  source={TRADER_PROFIT_TRANSLATION}
                  value={traderProfit}
                  currency={TarkovCurrency.RUB}
                  color={tradeValueColor[traderProfit]}
                  showDelta
                />
                <ItemPriceDetails
                  source={FEE_TRANSLATION}
                  value={fleaTax}
                  currency={TarkovCurrency.RUB}
                  color="var(--shade0"
                />
                <PercentageChangeDetails
                  source={LAST_DAY_CHANGE_TRANSLATION}
                  value={dayChange}
                  showDelta
                />
                <PercentageChangeDetails
                  source={LAST_WEEK_CHANGE_TRANSLATION}
                  value={weekChange}
                  showDelta
                />
              </div>
            </div>
            {otherItems?.length > 0 && (
              <div {...classNames(itemDetailsCardCss, "pad-edges")}>
                <div className="flex column gap-4">
                  <CategoryHeader category={categoryInfo} isShowMore />
                  <div
                    className={itemWrapperCss()}
                    style={{ "--item-width": "0" }}
                  >
                    {otherItems.map(([otherItemId]) => (
                      <FleaItemTile itemId={otherItemId} key={otherItemId} />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            style={{ "--card-inset-border": "var(--shade3-15)" }}
            {...classNames("span-1", "item-details", itemDetailsTable)}
          >
            <div className={itemDetailsCardCss()}>
              <ItemInfoTableHeader>
                <ItemGrid
                  width={item.width}
                  height={item.height}
                  img={getItemImageURL(itemId)}
                  alt={t(...item.name)}
                  className="item-display"
                />
              </ItemInfoTableHeader>
              <DataTable
                borderless
                sortable={false}
                className={tableStyles()}
                cols={[
                  {
                    display: t("eft:fleaMarket.headerType", "Type"),
                    align: "left",
                  },
                  {
                    display: t("eft:fleaMarket.price", "Price"),
                  },
                ]}
                rows={itemTableRows}
              />
            </div>
          </div>
        </div>
      </PageContainer>
    </WikiDataContextProvider>
  );
}

export function meta([itemSlug]): Meta {
  const { items } = getWikiData();
  const item = Object.values<WikiItem>(items ?? {}).find(
    (item) => item.slug === itemSlug,
  );

  const itemName = item
    ? translate(...item.name)
    : translate("common:item", "Item");

  const description = item
    ? item.description
    : ([
        "eft:fleaItemSeo.description",
        "Explore item details in Escape from Tarkov.",
      ] as const);

  return {
    title: [
      "eft:fleaItemSeo.title",
      "{{itemName}} in Escape from Tarkov: Item Details | Blitz",
      { itemName },
    ],
    description,
    subtitle: true,
  };
}
